import useAuth from '../../core/hooks/useAuth';
import { Pedidos } from '../@types/Pedidos';
import Service from '../Service';

class PedidosService extends Service {
  static getPedidos(tokenTechsapp: string, plataforma: string) {
    return this.Http2.get<any>(
      `http://www.${plataforma}.com.br/backend/api/pdv/pedido/getAbertosCompleto`,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }

  static getById(id: string, tokenTechsapp: string, plataforma: string) {
    return this.Http2.get<Pedidos.CupomCompleto>(
      `http://www.${plataforma}.com.br/backend/api/pdv/pedido/getById/` + id,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }

  static insertNewCupomInvoice(payment: Pedidos.CupomInvoiceInput) {
    return this.HttpTechNFCE.post<Pedidos.RetornoGerarNota>(
      '/v1/nota/gerarNota',
      payment,
      {}
    ).then(this.getData);
  }

  static insertNewCupom(payment: Pedidos.CupomInput) {
    return this.Http.post<Pedidos.CupomInput>('/v1/cupom-fiscal/', payment, {}).then(
      this.getData
    );
  }
  static getByToken(token: string, tokenTechsapp: string, plataforma: string) {
    return this.Http2.get<Pedidos.CupomCompleto>(
      `http://www.${plataforma}.com.br/v1/cupom-fiscal/` + token,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }
  static getListaCupomFiscal() {
    return this.Http.get<Pedidos.cupomFiscalEmbedded>(`/v1/cupom-fiscal/`, {
      headers: { Authorization: `Basic aG9tb2xvZzoxMjM=` },
    }).then(this.getData);
  }

  static getPedidoById(id: string, tokenTechsapp: string, plataforma: string) {
    return this.Http2.get<Pedidos.CupomCompleto>(
      `http://www.${plataforma}.com.br/backend/api/v2/pedido/mostrar/` + id,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }

  static getCidade(cidade: string) {
    return this.Http.get<Pedidos.cidade>(`/v1/cidades/listarPorNome/` + cidade, {
      headers: { Authorization: `Basic aG9tb2xvZzoxMjM=` },
    }).then(this.getData);
  }

  static getEstado(estado: string) {
    return this.Http.get<Pedidos.estado>(`/v1/estados/buscarUf/` + estado, {
      headers: { Authorization: `Basic aG9tb2xvZzoxMjM=` },
    }).then(this.getData);
  }

  static newCidade(cidade: Pedidos.inputCidade) {
    return this.Http.post<Pedidos.cidade>('/v1/cidades/', cidade, {
      headers: { Authorization: `Basic aG9tb2xvZzoxMjM=` },
    }).then(this.getData);
  }

  static newEstado(estado: Pedidos.inputEstado) {
    return this.Http.post<Pedidos.estado>('/v1/estados/', estado, {
      headers: { Authorization: `Basic aG9tb2xvZzoxMjM=` },
    }).then(this.getData);
  }
  static confirmarCupom(
    token: number,
    cupom: { chave_cupom: string; numero_cupom: string },
    tokenTechsapp: string,
    plataforma: string
  ) {
    return this.Http2.post<any>(
      `http://www.${plataforma}.com.br/backend/api/pdv/pedido/finalizarPedido/` + token,
      cupom,
      {
        headers: { Authorization: `Basic ${tokenTechsapp}` },
      }
    ).then(this.getData);
  }
}

export default PedidosService;

import { Pedidos } from '../sdk/@types/Pedidos';

function transformaTabelaCupons(
  cupons: Pedidos.cupomFiscalEmbedded
): Pedidos.tabelaCuponsFiscais[] {
  let tabelaCupons: Pedidos.tabelaCuponsFiscais[] = [];
  cupons._embedded.cupomFiscal.map((cupomFiscal) => {
    const data: Pedidos.tabelaCuponsFiscais = {
      token: cupomFiscal.token,
      nome: cupomFiscal.nomeCompleto,
      frete: cupomFiscal.totalFrete,
      subtotal: cupomFiscal.subtotal,
      total: cupomFiscal.total,
      endereco: cupomFiscal.endereco.logradouro + cupomFiscal.endereco.numero,
    };
    tabelaCupons.push(data);
  });

  return tabelaCupons;
}

export default transformaTabelaCupons;

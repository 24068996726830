import TabelaUsuarios from '../../componentes/TabelaUsuarios';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaUsuariosView() {
  usePageTitle('Lista de Pedidos');
  useBreadcrumb('Consulta');
  return <TabelaUsuarios></TabelaUsuarios>;
}

export default ConsultaUsuariosView;

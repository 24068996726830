import TabelaClientes from '../../componentes/TabelaClientes';
import TabelaUsuarios from '../../componentes/TabelaUsuarios';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';

function ConsultaClientesView() {
  usePageTitle('Lista de Usuarios');
  useBreadcrumb('Consulta Usuarios');
  return <TabelaClientes></TabelaClientes>;
}

export default ConsultaClientesView;

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import handleAxiosResponseSuccess from './utils/handleAxiosResponseSuccess';

const Http = axios.create();
const Http2 = axios.create();
const HttpTechNFCE = axios.create();

class Service {
  protected static Http = Http;
  protected static Http2 = Http2;
  protected static HttpTechNFCE = HttpTechNFCE;

  protected static getData = getData;

  public static setRequestInterceptors(
    onFulfilled: (
      request: AxiosRequestConfig
    ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
    onRejected?: (error: any) => any
  ) {
    Http.interceptors.request.use(onFulfilled, onRejected);
  }

  public static setResponseInterceptors(
    onFulfilled: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
    onRejected: (error: any) => any
  ) {
    Http.interceptors.response.use(onFulfilled, onRejected);
  }
}

function getData<T>(res: AxiosResponse<T>) {
  return res.data;
}

Http.defaults.baseURL = 'http://tech-union.ddns.net:8077';
Http2.defaults.baseURL = 'http://lifix.com.br';
HttpTechNFCE.defaults.baseURL = 'http://tech-union.ddns.net:8078';

export default Service;

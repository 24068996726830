import { useEffect, useState } from 'react';
import { Pedidos } from '../@types/Pedidos';
import Service from '../Service';
import PedidosService from './Pedidos.service';

let pedidoCodificado: Pedidos.CupomCompleto;
let cupomInput: Pedidos.CupomInput;
let cupomInvoiceInput: Pedidos.CupomInvoiceInput;
let cidadeAux: string;
let cidade: Pedidos.cidade;
let estado: Pedidos.estado;
let cidadeInput: Pedidos.inputCidade;
let estadoAux: string;
let buscouPedido = false;
let buscouCidade = false;
let buscouEstado = false;

class CupomService extends Service {
  static async getGerarCupom(
    token: string,
    id: string,
    tokenTechsapp: string,
    plataforma: string,
    terminal: string
  ) {
    await PedidosService.getPedidoById(id as string, tokenTechsapp, plataforma).then(
      (finalResult) => {
        pedidoCodificado = finalResult;
        buscouPedido = true;
      }
    );

    while (!buscouPedido && !buscouCidade && !buscouEstado) {
      setTimeout(() => {
        // console.log('Ainda não buscou');
      }, 1000);
    }
    //console.log('cidade');
    //console.log(cidade);
    //console.log('estado');
    //console.log(estado);
    //console.log('pedidoCodificado');
    //console.log(pedidoCodificado);
    let cupom: string = '';
    let pedido: Pedidos.PedidoTechsapp;
    let tokenNota: string;

    const itensPedido: any = [];
    const detItem: any = [];

    if (pedidoCodificado) {
      tokenNota = pedidoCodificado.token;
      cupom = atob(pedidoCodificado.cupom);
      pedido = JSON.parse(atob(pedidoCodificado.pedido));
      cidadeAux = pedido.cidadeIntegracao;
      estadoAux = pedido.estadoIntegracao;

      await PedidosService.getEstado(estadoAux).then((finalResult) => {
        estado = finalResult;
        buscouEstado = true;
      });

      await PedidosService.getCidade(cidadeAux).then((finalResult) => {
        cidade = finalResult;
        //console.log(cidade);
        //console.log(cidade._embedded);
        //console.log(estado);
        //console.log(estado._embedded);
        buscouCidade = true;
      });

      if (!cidade._embedded) {
        //console.log('cidade encontrada');
        if (estado._embedded) {
          cidadeInput = {
            estado: {
              id: estado._embedded.estados[0].id,
            },
            nome: cidadeAux,
          };
          await PedidosService.newCidade(cidadeInput);

          await PedidosService.getCidade(cidadeAux).then((finalResult) => {
            cidade = finalResult;
            // console.log(cidade);
            // console.log(cidade._embedded);
            // console.log(estado);
            // console.log(estado._embedded);
            buscouCidade = true;
          });
        } else {
          const estadoInput: Pedidos.inputEstado = {
            nome: estadoAux,
            uf: estadoAux,
          };

          await PedidosService.newEstado(estadoInput);

          await PedidosService.getEstado(estadoAux).then((finalResult) => {
            estado = finalResult;
            buscouEstado = true;

            cidadeInput = {
              estado: {
                id: estado._embedded.estados[0].id,
              },
              nome: cidadeAux,
            };
          });

          await PedidosService.newCidade(cidadeInput);

          await PedidosService.getCidade(cidadeAux).then((finalResult) => {
            cidade = finalResult;
            //console.log(cidade);
            //console.log(cidade._embedded);
            // console.log(estado);
            // console.log(estado._embedded);
            buscouCidade = true;
          });
        }
      }
      console.log('pedido');
      console.log(pedido);
      pedido.produtos.map((element3) => {
        const pedidoAtual = {
          codigo: element3.codigoIntegracao,
          descricaoCompleta: element3.nome,
          descricaoResumida: element3.nome,
          observacao: element3.obs,
          quantidade: element3.quantidade,
          valor: element3.valor,
        };
        const produtoAtual = {
          prod: {
            cProd: element3.codigoIntegracao,
            cEAN: 'SEM GTIN',
            xProd: 'NOTA FISCAL EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL',
            NCM: '19059010',
            CFOP: '5102',
            uCOM: 'UN',
            qCOM: '' + element3.quantidade + '',
            vUnCom: '' + element3.valor / element3.quantidade + '',
            vProd: '' + element3.valor + '',
            cEANTrib: 'SEM GTIN',
            uTrib: 'UN',
            qTrib: '' + element3.quantidade + '',
            vUnTrib: '' + element3.valor / element3.quantidade + '',
            indTot: '1',
            nTipoItem: '0',
          },
          imposto: {
            ICMS: {
              orig: '0',
              CST: '102',
            },
          },
        };
        detItem.push(produtoAtual);
        itensPedido.push(pedidoAtual);
        console.log('pedidoAtual');
        console.log(pedidoAtual);
      });
      //console.log(pedido);
      //console.log(cidade?._embedded.cidades[0].id);
      pedido.endereco && cidade?._embedded.cidades[0].id
        ? (cupomInput = {
            cnpj: '.',
            cpf: '.',
            endereco: {
              bairro: pedido.bairro ? pedido.bairro : '.',
              cep: pedido.cep ? pedido.cep : '.',
              cidade: {
                id: cidade?._embedded.cidades[0].id,
              },
              complemento: pedido.complemento ? pedido.complemento : '.',
              logradouro: pedido.ruaIntegracao ? pedido.ruaIntegracao : '.',
              numero: pedido.numero ? pedido.numero : '0',
            },
            formaPagamento: pedido.pagamento,
            ie: '.',
            itens: itensPedido,
            nomeCompleto: pedido.nome,
            observacao: '.',
            rg: '.',
            subtotal: pedido.subtotal.replace('.', ''),
            telefone: pedido.telefone,
            token: pedido.token,
            total: pedido.total,
            totalFrete: pedido.frete,
          })
        : (cupomInput = {
            cnpj: '.',
            cpf: '.',
            formaPagamento: pedido.pagamento,
            ie: '.',
            itens: itensPedido,
            nomeCompleto: pedido.nome,
            observacao: '.',
            rg: '.',
            subtotal: pedido.subtotal,
            telefone: pedido.telefone,
            token: pedido.token,
            total: pedido.total,
            totalFrete: pedido.frete,
          });
    }
    console.log(cupomInput);
    console.log(cidadeAux);
    console.log(estadoAux);
    cupomInvoiceInput = {
      ModeloDocumento: 'NFCe',
      Versao: '4.0',
      ide: {
        cNF: '00000001',
        cUF: '35',
        natOp: 'Venda no supermercado',
        indPag: '0',
        mod: '65',
        serie: terminal,
        nNF: '63',
        dhEmi: '2022-06-17T15:41:17',
        fusoHorario: '-03:00',
        tpNf: '1',
        idDest: '1',
        indFinal: '1',
        indPres: '1',
        cMunFg: '3550308',
        tpImp: '4',
        tpEmis: '1',
        tpAmb: '2',
        finNFe: '1',
        procEmi: '0',
      },
      emit: {
        CNPJ_emit: '19593690000168',
        xNome: 'CRISTINA APARECIDA DE GOES',
        IE: '143180145110',
        CRT: '1',
        enderEmit: {
          xLgr: 'VITOR EMANUEL',
          nro: '31',
          xBairro: 'SACOMA',
          cMun: '3550308',
          xMun: 'SAO PAULO',
          UF: 'SP',
          CEP: '04174000',
        },
      },
      dest: {
        CPF_dest: '51986889831',
        indIEDest: '9',
        xNome_dest: 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL',
      },
      det: {
        detItem: detItem,
      },
      total: {
        ICMStot: {
          vBC_ttlnfe: '0.00',
          vICMS_ttlnfe: '0.00',
          vICMSDeson_ttlnfe: '0.00',
          vBCST_ttlnfe: '0.00',
          vST_ttlnfe: '0.00',
          vProd_ttlnfe: '10.00',
          vFrete_ttlnfe: '0.00',
          vSeg_ttlnfe: '0.00',
          vDesc_ttlnfe: '0.00',
          vIPI_ttlnfe: '0.00',
          vPIS_ttlnfe: '0.00',
          vCOFINS_ttlnfe: '0.00',
          vOutro: '0.00',
          vNF: '10.00',
          vTotTrib_ttlnfe: '0.00',
        },
      },
      transp: {
        modFrete: '9',
      },
      pag: {
        pagItem: [
          {
            tPag: '01',
            vPag: '10.00',
          },
        ],
      },
    };

    console.log(cupomInvoiceInput);
    const retornoApiNfe: Pedidos.RetornoGerarNota =
      await PedidosService.insertNewCupomInvoice(cupomInvoiceInput);
    if (retornoApiNfe.documento.docPDFDownload) {
      return window.open(retornoApiNfe.documento.docPDFDownload);
    } else {
      window.alert(retornoApiNfe.documento.sitDescricao);
    }

    //return PedidosService.insertNewCupom(cupomInput);
  }
}

export default CupomService;

import axios from 'axios';
import Service from '../sdk/Service';

import AuthService from './Authorization.service';

Service.setRequestInterceptors(async (request) => {
  const accessToken = AuthService.getAccessToken();

  // injeta o token de acesso na requisição
  if (accessToken) {
    if (request.baseURL === 'http://tech-union.ddns.net:8077') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
    if (request.baseURL === 'http://tech-union.ddns.net:8078') {
      if (request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`;
      }
    }
  }

  console.log(request);
  return request;
});

Service.setResponseInterceptors(
  (response) => response,

  async (error) => {
    // recupera informações da requisição
    const originalRequest = error.config;
    console.log(error);

    if (error?.response?.status === 401) {
      AuthService.imperativelySendToLogout();
    }
    if (error?.response?.status === 404) {
      return {};
    }

    throw error;
  }
);

import { PrinterOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserService } from '../sdk';
import { Pedidos } from '../sdk/@types/Pedidos';
import { Usuarios } from '../sdk/@types/Usuarios';
import PedidosService from '../sdk/services/Pedidos.service';
import transformarClientes from './TransformarClientes';
import transformaTabelaCupons from './TransformaTabelaCupons';

export default function TabelaClientes() {
  const [cupons, setCupons] = useState<Pedidos.tabelaCuponsFiscais[]>();

  let id = 'MjAyMjAyMjUxMTI4MjBAaG9tb2xvZ2FjYW8=';

  const [error, setError] = useState<Error>();

  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const columns: any = [
    {
      title: 'Token',
      dataIndex: 'token',
      key: 'token',
    },
    {
      title: 'Cliente',
      dataIndex: 'nome',
      key: 'cliente',
    },
    {
      title: 'Frete',
      dataIndex: 'frete',
      key: 'frete',
    },
    {
      title: 'Endereço',
      dataIndex: 'endereco',
      key: 'endereco',
    },
  ];

  useEffect(() => {
    PedidosService.getListaCupomFiscal()
      .then(transformaTabelaCupons)
      .then(setCupons)
      .catch((error) => {
        setError(new Error(error.message));
      });
  }, []);

  return <Table dataSource={cupons} columns={columns} />;
}

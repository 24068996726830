// User.service.ts
import transformarDados from '../../app/funcoes/TransformarDados';
import { Usuarios } from '../@types/Usuarios';
import Service from '../Service';

class UserService extends Service {
  static getGrupoUsuario(userId: number) {
    return this.Http.get<Usuarios.ListaGrupos>(`/v1/usuarios/${userId}/grupos`).then(
      this.getData
    );
  }
  static getRestaurantes(userId: number) {
    return this.Http.get<Usuarios.ListaRestaurantes>(
      `/v1/restaurantes/usuario/${userId}`
    ).then(this.getData);
  }

  static getRestaurantesByID(restauranteId: string) {
    return this.Http.get<Usuarios.ListaRestaurantes>(
      `/v1/restaurantes/${restauranteId}`
    ).then(this.getData);
  }
  static getAllRestaurantes() {
    return this.Http.get<Usuarios.ArrayDeRestaurantesDoUsuario>(`/v1/restaurantes`).then(
      this.getData
    );
  }

  static associarGrupo(idUsuario: number, idGrupo: number) {
    return this.Http.put<{}>(`/v1/usuarios/${idUsuario}/grupos/${idGrupo}`).then(
      this.getData
    );
  }

  static getUsuarioDetalhado(userId: number) {
    return this.Http.get<Usuarios.UsuarioDetalhado>(`/v1/usuarios/${userId}`).then(
      this.getData
    );
  }

  static getAllUsers() {
    return this.Http.get<Usuarios.ListaUsuarios>('/v1/usuarios').then(this.getData);
  }

  static insertNewUser(userData: Usuarios.Input) {
    const data = transformarDados(userData);

    return this.Http.post<Usuarios.RetornoCadastro>('/v1/usuarios', data).then(
      this.getData
    );
  }
}

export default UserService;

import { PrinterOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Space } from 'antd';
import { Col, Form, Input, Row, Select, Button, notification, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useAuth from '../core/hooks/useAuth';
import { updateUser } from '../core/store/Auth.slice';
import { UserService } from '../sdk';
import { Pedidos } from '../sdk/@types/Pedidos';
import { Lojas } from '../sdk/@types/Restaurantes';
import { Usuarios } from '../sdk/@types/Usuarios';
import LojaService from '../sdk/services/Loja.service';
import PedidosService from '../sdk/services/Pedidos.service';
import modificaPedidos from './PegarCliente';

export default function TabelaUsuarios() {
  const [pedidos, setPedidos] = useState<Pedidos.ListaIdPedidosComCliente>();
  const [lojas, setLojas] = useState<Usuarios.comboLojas[]>();
  const [data, setData] = useState<Lojas.TerminalTratado[]>([]);
  const [terminal, setTerminal] = useState<string>('');
  const [tabelaPedidos, setTabelaPedidos] = useState<boolean>(true);
  const dispatch = useDispatch();
  let { user } = useAuth();
  const [error, setError] = useState<Error>();
  const [restauranteSelecionado, setRestauranteSelecionado] =
    useState<Usuarios.comboLojas>();
  const [trava, setTrava] = useState<boolean>(true);
  const [travaTerminais, setTravaTerminais] = useState<boolean>(true);

  const [fetching, setFetching] = useState<boolean>(true);

  const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const { Option } = Select;

  const [restauranteAtual, setRestauranteAtual] = useState<Usuarios.ListaRestaurantes>();

  function trataRestauranteAtual(
    restaurantes: Usuarios.ListaRestaurantes
  ): Usuarios.ListaRestaurantes {
    console.log(restaurantes);

    if (user && restaurantes) {
      let userAux = {
        grupos: user.grupos,
        usuario: user.usuario,
        restaurantes: restaurantes,
      };
      user = userAux;
      console.log(userAux);
      console.log(restaurantes);
      userAux.restaurantes = restaurantes;
      dispatch(updateUser(userAux));

      console.log(user.restaurantes);
    }
    return restaurantes;
  }
  const onChange = (value: any) => {
    value = JSON.parse(value);

    setRestauranteSelecionado(value as Usuarios.comboLojas);
    UserService.getRestaurantesByID(value.id)
      .then(trataRestauranteAtual)
      .then(setRestauranteAtual);

    if (value.token) {
      tokenTechsapp = value.token;
    }

    if (value.dominio) {
      plataforma = value.dominio;
    }

    setTabelaPedidos(false);
    PedidosService.getPedidos(tokenTechsapp, plataforma)
      .then(modificaPedidos)
      .then(setPedidos)
      .catch((error) => {
        setError(new Error(error.message));
      });

    setTabelaPedidos(true);
    if (tokenTechsapp) {
      setTravaTerminais(false);
    }
  };

  useEffect(() => {
    if (user?.restaurantes) {
      let cnpjAux = user?.restaurantes?.cnpj?.replaceAll('.', '');
      cnpjAux = cnpjAux?.replaceAll('/', '');
      cnpjAux = cnpjAux?.replaceAll('-', '');

      if (cnpjAux) {
        LojaService.getTerminaisDoEmissor(cnpjAux)
          .then(tratarListaDeEmissores)
          .then(setData)
          .catch((error) => {
            new Error(error.message);
          });
        setTravaTerminais(false);
      }
      setFetching(false);
    }

    if (restauranteSelecionado) {
      let cnpjAux = restauranteSelecionado.cnpj?.replaceAll('.', '');
      cnpjAux = cnpjAux?.replaceAll('/', '');
      cnpjAux = cnpjAux?.replaceAll('-', '');

      if (cnpjAux) {
        LojaService.getTerminaisDoEmissor(cnpjAux)
          .then(tratarListaDeEmissores)
          .then(setData)
          .catch((error) => {
            new Error(error.message);
          });
      }
      setFetching(false);
    } else {
      setData([]);
      setFetching(false);
    }
  }, [user, restauranteSelecionado]);

  function tratarListaDeEmissores(dados: any): any {
    if (dados.status === 404) {
    }

    setFetching(false);
    return dados._embedded.terminal;
  }

  const onChangeTerminais = (value: any) => {
    value = JSON.parse(value);

    setTerminal(value);

    setTrava(false);
    if (terminal) {
    }
  };

  const onLoad = (value: number) => {
    //setCidadeEscolida(value);
  };

  const onSearch = (value: string) => {};

  const onSearchTerminais = (value: string) => {};

  const getColumnSearchProps = (
    dataIndex: keyof any,
    displayName?: string
  ): ColumnProps<any> => ({
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => (
      <Card>
        <Input
          style={{ marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          //placeholder={`Buscar ${displayName || dataIndex}`}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
          }}
          onPressEnter={() => confirm()}
        />
        <Space>
          <Button
            type={'primary'}
            size={'small'}
            style={{ width: 90 }}
            onClick={() => confirm()}
            icon={<SearchOutlined />}
          >
            Buscar
          </Button>

          <Button onClick={clearFilters} size={'small'} style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </Card>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#0099ff' : undefined }} />
    ),
    // @ts-ignore
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : '',
  });

  const columns = [
    {
      title: 'Token',
      dataIndex: 'token',
      ...getColumnSearchProps('token', 'Token'),
      key: 'token',
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
    },
    {
      title: 'Total',
      dataIndex: 'total_pedido',
      key: 'total_pedido',
    },
    {
      title: 'Ação',
      dataIndex: 'id',
      key: 'id',
      render: (text: string) => (
        <Button type='primary' disabled={trava}>
          <Link to={'/pedidos/notas?id=' + text + '&terminais=' + terminal}>
            <PrinterOutlined />
          </Link>
        </Button>
      ),
    },
  ];

  const tabelaPedidosGrade = <Table dataSource={pedidos} columns={columns} />;

  let tokenTechsapp = '';
  let plataforma = '';
  if (user?.restaurantes) {
    tokenTechsapp = user?.restaurantes?.basic_token as string;
    plataforma = user?.restaurantes?.dominio as string;
  }

  useEffect(() => {
    if (tokenTechsapp !== '') {
      PedidosService.getPedidos(tokenTechsapp, plataforma)
        .then(modificaPedidos)
        .then(setPedidos)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp]);

  useEffect(() => {
    console.log(user?.grupos?._embedded.grupos[0].id);
    if (user?.grupos?._embedded.grupos[0].id == 1) {
      setLojas(trataLojasUsuario(user));
    }
    if (user?.grupos?._embedded.grupos[0].id == 5) {
      UserService.getAllRestaurantes()
        .then(trataLojas)
        .then(setLojas)
        .catch((error) => {
          setError(new Error(error.message));
        });
    }
  }, [tokenTechsapp]);

  function trataLojas(
    lojas: Usuarios.ArrayDeRestaurantesDoUsuario
  ): Usuarios.comboLojas[] {
    console.log(lojas);
    let comboRestaurantes = Array();
    let indexCombo = 0;
    lojas._embedded.restaurantes.map((restaurante) => {
      const restauranteAux: Usuarios.comboLojas = {
        id: restaurante.id,
        index: indexCombo,
        nome: restaurante.nome,
        dominio: restaurante.dominio,
        token: restaurante.basic_token,
        cnpj: restaurante.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    });

    return comboRestaurantes;
  }

  function trataLojasUsuario(
    usuario: Usuarios.UsuarioDetalhadoGrupo
  ): Usuarios.comboLojas[] {
    let comboRestaurantes = Array();
    let indexCombo = 0;
    if (usuario.restaurantes) {
      const restauranteAux: Usuarios.comboLojas = {
        id: usuario.restaurantes.id,
        index: indexCombo,
        nome: usuario.restaurantes.nome,
        dominio: usuario.restaurantes.dominio,
        token: usuario.restaurantes.basic_token,
        cnpj: usuario.restaurantes.cnpj,
      };

      indexCombo++;
      comboRestaurantes.push(restauranteAux);
    }

    return comboRestaurantes;
  }

  return (
    <>
      <Form
        autoComplete={'off'}
        layout={'horizontal'}
        onFinish={async (user: Usuarios.Input) => {
          try {
            let idUsu = await UserService.insertNewUser(user);
            if (user.grupo) {
              UserService.associarGrupo(idUsu.id, user.grupo);
            }
            notification.success({
              message: 'Sucesso',
              description: 'usuário criado com sucesso',
            });
          } catch (error) {}
        }}
      >
        <Row align={'middle'} gutter={24}>
          {user?.grupos?._embedded.grupos[0].id == 5 ? (
            <Col xs={24} lg={13}>
              <Form.Item label={'Loja'} name={'loja'} style={{ width: '70%' }}>
                <Select
                  placeholder={'Selecione o loja'}
                  showSearch
                  onChange={onChange}
                  onSearch={onSearch}
                  optionFilterProp='children'
                  defaultValue={lojas ? lojas[0].nome : ''}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  {lojas?.map((restaurante) => (
                    <Select.Option value={JSON.stringify(restaurante)}>
                      {restaurante.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ''
          )}

          <Col xs={24} lg={13}>
            <Form.Item label={'Terminal'} name={'terminal'} style={{ width: '70%' }}>
              <Select
                placeholder={'Terminal'}
                showSearch
                onChange={onChangeTerminais}
                onSearch={onSearchTerminais}
                optionFilterProp='children'
                disabled={travaTerminais}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {data.map((terminal) => (
                  <Select.Option value={terminal.serie}>{terminal.serie}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {tabelaPedidos ? tabelaPedidosGrade : ''}
    </>
  );
}

import { Alert, Button } from 'antd';
import { useEffect, useState } from 'react';
import transformarPedidos from '../../componentes/TransformarPedidos';
import useAuth from '../../core/hooks/useAuth';
import useBreadcrumb from '../../core/hooks/useBreadcrumb';
import usePageTitle from '../../core/hooks/usePageTitle';
import { Pedidos } from '../../sdk/@types/Pedidos';
import CupomService from '../../sdk/services/Cupom.service';
import PedidosService from '../../sdk/services/Pedidos.service';
import useGetParametro from '../../sdk/utils/hooks/useGetParametro';
import useGetToken from '../../sdk/utils/hooks/useGetParametro';
import TrataCupom from '../../sdk/utils/TrataCupom';

function CadastroDeUsuarioView() {
  usePageTitle('Cadastro de Usuario');
  useBreadcrumb('cadastro');

  const [pedido, setPedido] = useState<Pedidos.CupomCompletoPedidoDecodificado>();
  const { user } = useAuth();
  const token = new URLSearchParams(window.location.search).get('token');
  const id = new URLSearchParams(window.location.search).get('id');
  const terminal = new URLSearchParams(window.location.search).get('terminais');
  const [fetching, setFetching] = useState<boolean>(true);
  let tokenTechsapp = user?.restaurantes?.basic_token as string;
  let plataforma = user?.restaurantes?.dominio as string;
  useEffect(() => {
    if (tokenTechsapp) {
      if (token) {
        PedidosService.getByToken(token, tokenTechsapp, plataforma)
          .then(transformarPedidos)
          .then(setPedido);

        setFetching(false);
      }
      if (id) {
        PedidosService.getPedidoById(id, tokenTechsapp, plataforma)
          .then(transformarPedidos)
          .then(setPedido);

        setFetching(false);
      }
    }
  }, []);

  return (
    <div>
      <div>
        Pedido {pedido?.cupomCompleto.token}
        <br />
        {pedido?.cupomCompleto.data}
        <br />
        {pedido?.pedidoDecodificado.nome_loja}
        <br />
        {pedido?.pedidoDecodificado.bairroTratadoIntegracao} -{' '}
        {pedido?.pedidoDecodificado.cidadeIntegracao}
        <br />
        -----------------------------------------
        <br />
        Nome: {pedido?.pedidoDecodificado.nome}
        <br />
        Telefone: {pedido?.pedidoDecodificado.telefone}
        <br />
        <br />
        {pedido?.pedidoDecodificado.pagamento}
        <br />
        <br />
        Subtotal: {pedido?.pedidoDecodificado.subtotal}
        <br />
        Frete {pedido?.pedidoDecodificado.frete}
        <br />
        Total {pedido?.pedidoDecodificado.total}
        <br />
        -----------------------------------------
        <br />
        {pedido?.pedidoDecodificado.produtos.map((element) => (
          <p>{element.nome + ' R$ ' + element.valor}</p>
        ))}
        <br />
        ---------------------------------------
        <br />
        TechsApp - Venda Pelo WhatsApp
        <br />
        www.techsapp.com.br
        <br />
      </div>
      <p>
        <Button
          type={'primary'}
          htmlType={'button'}
          loading={fetching}
          onClick={async () => {
            setFetching(true);
            const resp = await CupomService.getGerarCupom(
              token as string,
              id as string,
              tokenTechsapp,
              plataforma,
              terminal ? terminal : ''
            );

            console.log(resp);
            if (resp != null) {
              if (pedido?.pedidoDecodificado) {
                const resposta = await PedidosService.confirmarCupom(
                  Number(pedido?.pedidoDecodificado.token),
                  {
                    chave_cupom: pedido?.pedidoDecodificado.token,
                    numero_cupom: pedido?.pedidoDecodificado.token,
                  },
                  tokenTechsapp,
                  plataforma
                );
                if (resposta != null) {
                  window.location.href = '/';
                }
              }

              window.alert('A nota foi gerada com sucesso!');
              setFetching(false);
            } else {
              window.alert('Ocorreu algum problema!');
              setFetching(false);
            }
          }}
        >
          {'Gerar Nota'}
        </Button>
      </p>
    </div>
  );
}

export default CadastroDeUsuarioView;

import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import useAuth from '../core/hooks/useAuth';
import HomeView from './views/Home.view';
import { useEffect } from 'react';
import AuthService from '../auth/Authorization.service';
import jwtDecode from 'jwt-decode';
import { useMemo } from 'react';
import { Authentication } from '../auth/Auth';
import GlobalLoading from '../components/GlobalLoading';
import { message, notification } from 'antd';
import ConsultaUsuariosView from './views/ConsultaUsuarios.view';
import CadastroDeUsuarioView from './views/CadastroDeUsuario.view';
import ConsultaClientesView from './views/ConsultaClientes.view';

const APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const APP_BASE_URL_PATH = process.env.REACT_APP_BASE_URL_PATH;

export default function Routes() {
  const history = useHistory();
  const location = useLocation();

  const { fetchUser, user } = useAuth();

  useEffect(() => {
    window.onunhandledrejection = ({ reason }) => {
      reason?.data?.objects?.forEach((object: { userMessage: string }) => {
        message.error(object.userMessage);
      });

      notification.error({
        message: reason?.message || 'Houve um erro',
      });
    };

    return () => {
      window.onunhandledrejection = null;
    };
  }, []);

  useEffect(() => {
    async function identify() {
      /* const isInAuthorizationRoute =
        '/' +
          window.location.pathname.substring(
            window.location.pathname.lastIndexOf('/') + 1
          ) ===
        '/authorize';*/

      const isInAuthorizationRoute = window.location.pathname.includes('/authorize');

      const code = new URLSearchParams(window.location.search).get('code');

      const accessToken = AuthService.getAccessToken();

      if (!accessToken && !isInAuthorizationRoute) {
        AuthService.imperativelySendToLoginScreen();
      }

      if (isInAuthorizationRoute) {
        if (!code) {
          notification.error({
            message: 'Código não foi informado',
          });
          AuthService.imperativelySendToLoginScreen();
          return;
        }
        // busca o primeiro token de acesso
        const { access_token } = await AuthService.getFirstAccessTokens({
          code,
          redirectUri: `${APP_BASE_URL}/authorize`,
        });

        AuthService.setAccessToken(access_token);

        const decodedToken: Authentication.AccessTokenDecodedBody =
          jwtDecode(access_token);

        if (!decodedToken.authorities) {
          window.alert('Você não tem acesso ao sistema!');
          AuthService.imperativelySendToLogout();
        }

        fetchUser(decodedToken.usuario_id);

        history.push('/');
      }

      if (accessToken) {
        const decodedToken: Authentication.AccessTokenDecodedBody =
          jwtDecode(accessToken);
        if (!decodedToken.authorities) {
          window.alert('Você não tem acesso ao sistema!');
          AuthService.imperativelySendToLogout();
        }
        fetchUser(decodedToken.usuario_id);
      }
    }

    identify();
  }, [history, fetchUser]);

  const isAuthorizationRoute = useMemo(
    () => location.pathname === '/authorize',
    [location.pathname]
  );

  // if (isAuthorizationRoute || !user) return <GlobalLoading />;
  if (isAuthorizationRoute) return <GlobalLoading />;

  return (
    <Switch>
      <Route path={`/`} exact component={HomeView} />
      <Route path={`/pedidos`} exact component={ConsultaUsuariosView} />
      <Route path={`/cuponsfiscais`} exact component={ConsultaClientesView} />
      <Route path={`/pedidos/notas`} exact component={CadastroDeUsuarioView} />
    </Switch>
  );
}
